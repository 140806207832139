import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { FC } from "react";
import { PageHeader } from "../components/PageHeader.component";

export const RsvpSent: FC<Record<string, never>> = () => {
  return (
    <Container maxWidth="md" sx={{ pt: "1rem", pb: "1rem" }}>
    <Box>
      <PageHeader heading="Svar mottaget" />
      <Typography>Tack för ditt svar!</Typography>
    </Box>
  </Container>
  )
};

export default RsvpSent;
