import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import React, { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import ErrorDialog from "../components/ErrorDialog.component";
import { PageHeader } from "../components/PageHeader.component";

interface FormPost {
  name: string;
  email: string;
  attending: string;
  alcoholfree: string;
  busto: string;
  bushome: string;
  other: string;
}

const StyledTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: "#fff",
  width: "50%",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

export const Rsvp: FC<Record<string, never>> = () => {
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [errorOpen, setErrorOpen] = useState<boolean>(false);

  const encode = (data: any) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  };

  const initialFormValues: FormPost = {
    name: "",
    email: "",
    attending: "",
    alcoholfree: "",
    busto: "",
    bushome: "",
    other: "",
  };

  const [formState, setFormstate] = useState<FormPost>(initialFormValues);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    setSubmitting(true);
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "rsvp", ...formState }),
    })
      .then(() => {
        setSubmitting(false);
        navigate("/thankyou");
      })
      .catch((error) => {
        console.error(error);
        setSubmitting(false);
        setErrorOpen(true);
      });
    event.preventDefault();
  };

  return (
    <Container maxWidth="md" sx={{ pt: "1rem", pb: "1rem" }}>
      <Box>
        <PageHeader heading="OSA" caption="Varje enskild person behöver anmäla sig. Vänligen svara senast 1 april 2023" />
        <form
          name="rsvp"
          method="POST"
          onSubmit={handleSubmit}
          data-netlify="true"
        >
          <input type="hidden" name="form-name" value="rsvp" />
          <FormControl
            sx={{ mb: "2rem", mt: "2rem", display: "block" }}
            required
          >
            <FormLabel sx={{ display: "block" }}>Namn</FormLabel>
            <StyledTextField
              required
              id="name"
              name="name"
              value={formState.name}
              onChange={(event) =>
                setFormstate({ ...formState, name: event.target.value })
              }
            />
          </FormControl>
          <FormControl
            sx={{ mb: "2rem", mt: "2rem", display: "block" }}
            required
          >
            <FormLabel sx={{ display: "block" }}>Epost</FormLabel>
            <StyledTextField
              required
              name="email"
              value={formState.email}
              onChange={(event) =>
                setFormstate({ ...formState, email: event.target.value })
              }
            />
          </FormControl>
          <FormControl sx={{ display: "block", mb: "2rem" }} required>
            <FormLabel>Kommer du?</FormLabel>
            <RadioGroup name="attending">
              <FormControlLabel
                value="yes"
                control={<Radio required />}
                label="Ja"
                onChange={() =>
                  setFormstate({ ...formState, attending: "yes" })
                }
              />
              <FormControlLabel
                value="no"
                control={<Radio required />}
                label="Nej"
                onChange={() => setFormstate({ ...formState, attending: "no" })}
              />
            </RadioGroup>
          </FormControl>
          <FormControl sx={{ display: "block", mb: "2rem" }} required>
            <FormLabel>Alkholfritt?</FormLabel>
            <RadioGroup name="alcoholfree">
              <FormControlLabel
                value="yes"
                control={<Radio required />}
                label="Ja"
                onChange={() =>
                  setFormstate({ ...formState, alcoholfree: "yes" })
                }
              />
              <FormControlLabel
                value="no"
                control={<Radio required />}
                label="Nej"
                onChange={() =>
                  setFormstate({ ...formState, alcoholfree: "no" })
                }
              />
            </RadioGroup>
          </FormControl>
          <FormControl sx={{ display: "block", mb: "2rem" }} required>
            <FormLabel>
              Vill du åka buss till lokalen innan ceremonin? (ca kl 14)
            </FormLabel>
            <RadioGroup name="busto">
              <FormControlLabel
                value="yes"
                control={<Radio required />}
                label="Ja"
                onChange={() => setFormstate({ ...formState, busto: "yes" })}
              />
              <FormControlLabel
                value="no"
                control={<Radio required />}
                label="Nej"
                onChange={() => setFormstate({ ...formState, busto: "no" })}
              />
            </RadioGroup>
          </FormControl>
          <FormControl sx={{ display: "block", mb: "2rem" }} required>
            <FormLabel>
              Vill du åka buss till centralen efter festen? (ca kl 02)
            </FormLabel>
            <RadioGroup name="bushome">
              <FormControlLabel
                value="yes"
                control={<Radio required />}
                label="Ja"
                onChange={() => setFormstate({ ...formState, bushome: "yes" })}
              />
              <FormControlLabel
                value="no"
                control={<Radio required />}
                label="Nej"
                onChange={() => setFormstate({ ...formState, bushome: "no" })}
              />
            </RadioGroup>
            <FormControl sx={{ mb: "2rem", mt: "2rem", display: "block" }}>
              <FormLabel sx={{ display: "block" }}>
                Andra upplysningar (allergier, veg, osv)
              </FormLabel>
              <StyledTextField
                name="other"
                onChange={(event) =>
                  setFormstate({ ...formState, other: event.target.value })
                }
              />
            </FormControl>
          </FormControl>
          <Button disabled={submitting} type="submit" variant="contained">
            Skicka
          </Button>
        </form>
        <ErrorDialog
          title="Fel vid inskick av formulär"
          description="Vänligen prova igen. Om det fortfarande inte fungerar, kontakta oss på annat sätt"
          onClose={() => setErrorOpen(false)}
          open={errorOpen}
        />
      </Box>
    </Container>
  );
};

export default Rsvp;
