import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../../views/Home.view";
import Info from "../../views/Info.view";
import Rsvp from "../../views/Rsvp.view";
import RsvpSent from "../../views/RsvpSent.view";
import Layout from "../Layout";

export const AppRoutes: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="info" element={<Info />} />
        <Route path="rsvp" element={<Rsvp />} />
        <Route path="thankyou" element={<RsvpSent />} />
      </Route>
    </Routes>
  );
};
