import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import { Link } from "react-router-dom";

type NavItem = {
  name: string;
  path: string;
};
const navItems: NavItem[] = [
  { name: "Lisa & Martin", path: "/" },
  { name: "Praktisk info", path: "/info" },
  { name: "OSA", path: "/rsvp" },
];

export const NavigationBar = () => (
  <Box sx={{ flexGrow: 1 }}>
    <AppBar position="static" sx={{background: '#FCF0F0'}} elevation={0}>
      <Toolbar>
        {navItems.map((item, index) => (
          <Link key={index} to={item.path}>
            <Button sx={{ color: "#000" }}>{item.name}</Button>
          </Link>
        ))}
      </Toolbar>
    </AppBar>
  </Box>
);

export default NavigationBar;
