import { FC } from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import L from 'leaflet';


import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import { styled } from "@mui/material/styles";

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconSize: [26, 36],
    iconAnchor: [17, 34]
});


L.Marker.prototype.options.icon = DefaultIcon;

const StyledMapContainer = styled(MapContainer)(({ theme }) => ({
    height: "300px",
    width: "100%",
  }));
const LocationMap: FC<Record<string, never>> = () => {
  return (
    <StyledMapContainer
      center={[59.4705056, 17.7738987]}
      zoom={13}
      scrollWheelZoom={false}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={[59.4705056, 17.7738987]}>
        <Popup>
          Almare Stäket Magasinet <br />
          Kortvretsvägen 1, 196 91 Kungsängen
        </Popup>
      </Marker>
    </StyledMapContainer>
  );
};

export default LocationMap;
