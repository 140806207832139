import { Button, Container, Divider, styled, Typography } from "@mui/material";
import { FC } from "react";
import { Link, useNavigate } from "react-router-dom";
import { WeddingTimeline } from "../components/WeddingTimeline";
import pic1 from "../assets/6.jpeg";
import LocationMap from "../components/LocationMap";

const Banner = styled("div")(({ theme }) => ({
  backgroundColor: "#E0D0C8",
}));

const BannerInfo = styled("div")(({ theme }) => ({
  width: "50%",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const BannerImage = styled("div")(({ theme }) => ({
  width: "50%",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    marginTop: "3rem",
  },
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const TestContainer = styled("div")(({ theme }) => ({
  display: "flex",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

const TimelineWrapper = styled("div")(({ theme }) => ({
  width: "50%",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
  marginTop: "1rem"
}));
const TextWrapper = styled("div")(({ theme }) => ({
  width: "100%",
  marginTop: "2rem"
}));

const MapWrapper = styled("div")(({ theme }) => ({
  width: "50%",
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: "2rem"
    },
}));

export const Home: FC<Record<string, never>> = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Banner>
        <Container
          maxWidth={"md"}
          sx={{ pt: "4rem", pb: "4rem", mb: "2rem", display: "flex" }}
        >
          <TestContainer>
            <BannerInfo>
              <Typography variant="h2">Vi gifter oss!</Typography>
              <Typography variant="subtitle1">
                <br />
                Lördag 3 juni 2023 <br />
                kl 15.00 <br />
                Almare Stäket, Magasinet <br />
                Kungsängen
              </Typography>
              <Button
                sx={{ marginTop: "1rem" }}
                variant="contained"
                onClick={() => navigate("/rsvp")}
              >
                O.S.A
              </Button>
              <Typography sx={{marginTop: "0.5rem"}} variant="subtitle2">
                Vänligen svara senast 1 april 2023.
              </Typography>
            </BannerInfo>
            <BannerImage>
              <img src={pic1} width="75%" height="auto" alt="" />
            </BannerImage>
          </TestContainer>
        </Container>
      </Banner>
      <Container maxWidth={"md"} sx={{ display: "flex", flexWrap: "wrap" }}>
        <Typography variant="h4" component="h2">
          Varmt välkomna på vårt bröllop!
        </Typography>
        <Typography>
          Vi ser verkligen fram emot vår stora dag och att fira den tillsammans
          med dig. På den här sidan har vi samlat en mängd information som kan
          vara bra att veta inför bröllopet. Nedan finner du program för dagen. Mer detaljerad information finner du <Link to="/info">här</Link>. 
        </Typography>
        <TextWrapper>
          <Typography sx={{ width: "100%" }} variant="h5">
            Bröllopsdagen
          </Typography>
          <Divider />
        </TextWrapper>
        <TimelineWrapper>
          <WeddingTimeline />
        </TimelineWrapper>
        <MapWrapper>
          <LocationMap />
        </MapWrapper>
      </Container>
    </div>
  );
};

export default Home;
