import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { Fragment } from "react";

export interface PageHeaderProps {
  heading: string;
  caption?: string;
}
export const PageHeader = ({ heading, caption }: PageHeaderProps) => {
  return (
    <Fragment>
      <Typography variant="h4" component="h1">
        {heading}
      </Typography>
      {caption && (
        <Typography variant="subtitle2">{caption}</Typography>
      )}
      <Divider sx={{ mb: "1rem" }}></Divider>
    </Fragment>
  );
};
