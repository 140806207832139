import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent, {
  timelineContentClasses,
} from "@mui/lab/TimelineContent";
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import People from "@mui/icons-material/People";
import LocalBar from "@mui/icons-material/LocalBar";
import Restaurant from "@mui/icons-material/Restaurant";
import Nightlife from "@mui/icons-material/Nightlife";
import Typography from "@mui/material/Typography";
import DirectionsBus from "@mui/icons-material/DirectionsBus";

export const WeddingTimeline = () => {
  return (
    <Timeline
      // position="alternate"
      // sx={{padding: 0}}
      sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },
      }}
    >
      <TimelineItem sx={{ height: "6rem" }}>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot variant="outlined">
            <DirectionsBus />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ m: "auto 0" }}>
          <Typography component="span">14:00 Buss avgår till Almare Stäket</Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem sx={{ height: "6rem" }}>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot variant="outlined">
            <People />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ m: "auto 0" }}>
          <Typography component="span">15:00 Ceremoni</Typography>
          <Typography variant="subtitle2">På gräset framför festlokalen</Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem sx={{ height: "6rem" }}>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot variant="outlined">
            <LocalBar />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ m: "auto 0" }}>
          <Typography component="span">16:00 Mingel</Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem sx={{ height: "6rem" }}>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot variant="outlined">
            <Restaurant />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ m: "auto 0" }}>
          <Typography component="span">18:00 Middag</Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem sx={{ height: "6rem" }}>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot variant="outlined">
            <Nightlife />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ m: "auto 0" }}>
          <Typography component="span">22:00 Dans</Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem sx={{ height: "6rem" }}>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot variant="outlined">
            <DirectionsBus />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ m: "auto 0" }}>
          <Typography component="span">02:00 Buss avgår till centrala Stockholm</Typography>
        </TimelineContent>
      </TimelineItem>
      {/* <TimelineItem sx={{ height: "6rem" }}>
        <TimelineOppositeContent
          sx={{ m: "auto 0" }}
          align="right"
          variant="body2"
          color="text.secondary"
        >
          15:00
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot variant="outlined">
            <People />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ m: "auto 0", flex: 0.2 }}>
          <Typography component="span">Ceremoni</Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem sx={{ height: "6rem" }}>
        <TimelineOppositeContent
          sx={{ m: "auto 0", flex: 0.2 }}
          variant="body2"
          color="text.secondary"
        >
          16:00
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot variant="outlined">
            <LocalBar />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ m: "auto 0" }}>
          <Typography component="span">Mingel</Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem sx={{ height: "6rem" }}>
        <TimelineOppositeContent
          sx={{ m: "auto 0" }}
          variant="body2"
          color="text.secondary"
        >
          18:00
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot variant="outlined">
            <Restaurant />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ m: "auto 0", flex: 0.2 }}>
          <Typography component="span">Middag</Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem sx={{ height: "6rem" }}>
        <TimelineOppositeContent
          sx={{ m: "auto 0", flex: 0.2 }}
          variant="body2"
          color="text.secondary"
          align="right"
        >
          22:00
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot variant="outlined">
            <Nightlife />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ m: "auto 0" }}>
          <Typography component="span">Dans</Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem sx={{ height: "6rem" }}>
        <TimelineOppositeContent
          sx={{ m: "auto 0" }}
          variant="body2"
          color="text.secondary"
        >
          02:00
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot variant="outlined">
            <DirectionsBus />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ m: "auto 0", flex: 0.2 }}>
          <Typography component="span">Buss hem</Typography>
        </TimelineContent>
      </TimelineItem> */}
    </Timeline>
  );
};
