import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { styled } from "@mui/material/styles";
import { FC } from "react";
import { PageHeader } from "../components/PageHeader.component";
import image1 from "../assets/1.jpeg";
import image2 from "../assets/2.jpeg";
import image3 from "../assets/3.jpeg";
import image4 from "../assets/4.jpeg";
import image5 from "../assets/5.jpeg";
import { Divider, Typography } from "@mui/material";

interface CardProps {
  reverse?: boolean;
}
const Card = styled("div")<CardProps>(({ theme, reverse }) => ({
  ...theme.typography.body2,
  display: "flex",
  flexWrap: "wrap",
  flexDirection: "row",
  paddingTop: "2rem",
  paddingBottom: "2rem",
  [theme.breakpoints.down("sm")]: {
    flexDirection: reverse ? "column-reverse" : "column",
  },
}));

 interface TextWrapperProps {
  fullWidth?: boolean;
}

const TextWrapper = styled("div")<TextWrapperProps>(({ theme, fullWidth = false }) => ({
  width: fullWidth ? "100%" : "50%",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const Text = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const ImageWrapper = styled("div")(({ theme }) => ({
  width: "50%",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    marginTop: "2rem",
  },
  gridArea: "image",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

export const Info: FC<Record<string, never>> = () => {
  return (
    <Container maxWidth="md" sx={{ pt: "1rem", pb: "1rem" }}>
      <Box>
        <PageHeader heading="Praktisk info" />
        <Card>
          <TextWrapper>
            <Typography variant="h5">Transport</Typography>
            <Text>
              Vi har bokat en buss som kommer att köra till och från
              bröllopslokalen. Bussen avgår från Cityterminalen kl 14.00 och
              åker tillbaka kl 02.00. Du anger om du vill åka med i
              anmälningsformuläret. Mer information om bussfärden mailas ut
              senare.
              <br />
              <br />
              Med bil är avståndet till bröllopslokalen cirka 30 minuter från
              centrala Stockholm och det finns parkering där. Det är en lång och
              krokig grusväg på slutet och där ska du följa skyltar mot
              ”Magasinet”. Du kommer passera flera byggnader och en hästgård
              innan du är framme. Efter hästgården ska du hålla höger när vägen delar sig.
            </Text>
          </TextWrapper>
          <ImageWrapper>
            <img src={image4} alt="image4" width="75%" height="auto" />
          </ImageWrapper>
        </Card>
        <Divider />
        <Card reverse>
          <ImageWrapper>
            <img src={image5} alt="image5" width="75%" height="auto" />
          </ImageWrapper>
          <TextWrapper>
            <Typography variant="h5">Boende</Typography>
            <Text>
              Vi kommer bo på{" "}
              <a
                href="https://www.nordicchoicehotels.se/hotell/sverige/stockholm/clarion-hotel-amaranten/"
                target="_blank"
                rel="noreferrer"
              >
                Clarion Hotel Amaranten
              </a>
              . Om du vill bo på samma hotell så rekommenderar vi att boka
              rummet så fort som möjligt då det finns bokningsalternativ med fri avbokning
              fram till ankomstdagen. Vi har tyvärr ingen möjlighet att
              reservera rum, så bokning sker på egen hand.
              <br />
              <br />
              Från hotellet är det gångavstånd till Cityterminalen där
              bussen avgår till bröllopslokalen. Det går såklart bra att välja
              andra boendealternativ och det finns flera andra hotell i
              närheten.
            </Text>
          </TextWrapper>
        </Card>
        <Divider />
        <Card>
          <TextWrapper fullWidth>
            <Typography variant="h5">Tal</Typography>
            <Text>
              Om du vill säga några ord under middagen så anmäler du det till
              våra toastmasters <strong>Linda Virkkala</strong> och{" "}
              <strong>Johanna Haglund Lindkvist</strong> i förväg.
              <br />
              <br />
              E-post: toast.lisamartin2023@gmail.com
              <br />
              <br />
              Kom ihåg att anmäla tal senast 1 maj 2023.
            </Text>
          </TextWrapper>
        </Card>
        <Divider />
        <Card>
          <TextWrapper fullWidth>
            <Typography variant="h5">Gåvor</Typography>

            <Text>
              Vi önskar oss framförallt att få dela denna dag med dig. Om du ändå
              vill ge en gåva får du gärna ge ett bidrag till vår bröllopsresa. Det görs enklast med Swish till 073-824 50 64 (Clara Pedersen Bergli, brudtärna).
            </Text>
          </TextWrapper>
        </Card>
        <Divider />
        <Card>
          <TextWrapper fullWidth>
            <Typography variant="h5">Klädkod</Typography>
            <Text>
              Klädkoden är kavaj. Du kan se klädkoden som ett riktmärke, det viktigaste för oss är
              att du är bekväm.
            </Text>
          </TextWrapper>
        </Card>
        <Divider />
        <Card>
          <TextWrapper fullWidth>
            <Typography variant="h5">Barn</Typography>
            <Text>
              Vi älskar era barn, men den här dagen kommer inte vara
              barnanpassad och vi firar helst kärleken med dem en annan dag.
              Ammande barn är såklart välkomna. Hör av dig om du har svårt att
              hitta barnvakt.
            </Text>
          </TextWrapper>
        </Card>
      </Box>
    </Container>
  );
};

export default Info;
